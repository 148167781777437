import React from 'react';
import { NavLink } from 'react-router-dom';

import Modal from 'antd/lib/modal';

import GetInTouchForm from '@app/components/Forms/GetInTouch/GetInTouchForm';
import '@app/scss/components/getInTouch.scss';
import { pushEvent } from '@app/components/TagManager';

interface GetInTouchButtonProps {
	btnText?: string;
	btnClassName?: string;
	openFormInModal?: boolean;
}

const GetInTouchButton: React.FC<GetInTouchButtonProps> = ({
	btnText = 'Get In Touch',
	btnClassName = 'btn btn-sm btn-primary',
	openFormInModal = false,
}) => {
	const [isVisible, setIsVisible] = React.useState(false);
	const [sent, setSent] = React.useState(false);

	const closeDialog = () => setIsVisible(false);

	return !openFormInModal ? (
		<NavLink to="/online-booking" className={btnClassName} onClick={() => pushEvent('onlineBookingScheduleBtnClick')}>
			{btnText}
		</NavLink>
	) : (
		<>
			<button type="button" className={btnClassName} onClick={() => setIsVisible(true)}>
				{btnText}
			</button>
			<Modal
				open={isVisible}
				title={<p>We're here to help</p>}
				onCancel={closeDialog}
				className="get-in-touch"
				wrapClassName="get-in-touch-back modal-wrap"
				footer={null}
				width={1008}
				centered
				destroyOnClose
			>
				<div className="get-in-touch-form">
					<GetInTouchForm onSubmit={() => setSent(true)} />
				</div>
				<div className="get-in-touch-info">
					<h2 className="get-in-touch-info__title">
						{sent ? (
							<>
								Thank you for reaching out to us.
								<br />
								A Care Coordinator will return
								<br />
								your message soon.
							</>
						) : (
							<>Have questions? Give us a call, one of our Care Coordinators would love to help.</>
						)}
					</h2>
					<p className="get-in-touch-info__text">
						{sent ? <>If you need immediate assistance, please call us at</> : <>We are here to answer your questions 7am-11pm EST</>}
					</p>
					<a className="get-in-touch-info__phone" href="tel:855-933-5683">
						<img className="get-in-touch-info__phone-icon" src={require('@app/images/call_icon.svg')} alt="Phone Icon" />
						<img
							className="get-in-touch-info__phone-icon get-in-touch-info__phone-icon_hovered"
							src={require('@app/images/call_icon_white-filled-white.svg')}
							alt="Phone Icon"
						/>
						<span>(855) 933-5683</span>
					</a>
				</div>
			</Modal>
		</>
	);
};

export default GetInTouchButton;
