/**
 * ## ItemProvider.tsx ##
 * This file contains ItemProvider component
 * @packageDocumentation
 */

import * as React from 'react';
import { useHistory } from 'react-router-dom';

import once from 'lodash/once';
import isEmpty from 'lodash/isEmpty';
import { AnySchema, ValidationError } from 'yup';

import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import useRequest from '@common/react/hooks/useRequest';
import { BaseParams } from '@common/react/objects/BaseParams';
import { ClearValue } from '@common/react/utils/utils';
import { useRequestProviderContext } from '@common/react/components/RequestProvider/RequestProvider';
import useAbortController from '@common/react/hooks/useAbortController';

export enum Mode {
	View,
	Edit
}

/**
 * This is the description of the interface
 *
 * @interface ItemProviderProps
 * @typeParam T - T Any WithDeleted entity
 */
export interface ItemProviderProps<T extends WithDeleted> {
	/**
	 * Element ID. Used as load param
	 */
	id: number;
	/**
	 * ReactElement to be wrapped in an ItemProvider context
	 */
	children: React.ReactNode;
	/**
	 * Schema for checking element before saving.
	 *
	 * - Need to set a default value for the property that will be validated
	 */
	validationSchema?: AnySchema;
	/**
	 * By default determines which item to load and how to save it
	 */
	type: string;
	/**
	 * Defines the default element if id < 0.
	 * Ignored if withoutAdd is set to true
	 */
	add?: Partial<T>;
	/**
	 * Defines the default element.
	 * Ignored if withoutAdd is set to false and id < 0
	 */
	item?: T | undefined;
	/**
	 * load request name. The default is made up of type.
	 */
	loadRequest?: string;
	/**
	 * load request name. The default is made up of type.
	 */
	saveRequest?: string;
	/**
	 * transform item before send to server
	 * @param item - element before submit
	 * @return the element to be sent in the request
	 */
	clearForSubmit?: (item: T) => ClearValue<T> | T;
	/**
	 * error handling function
	 * @param error - error text
	 */
	onRequestError?: ((error: string) => void);
	/**
	 * validation error handling function
	 * @param item - not valid element
	 * @param err - solved error
	 * @param error - original error object
	 */
	onValidationError?: ((item: T, err, error: ValidationError) => void);
	/**
	 * view mode
	 */
	readonly?: boolean;
	/**
	 * function to be called after load
	 * @param res - request response
	 */
	onLoad?: (res: T) => void;
	/**
	 * load params
	 */
	additionalParams?: BaseParams;
	/**
	 * function to be called after item change
	 * @param item - new element
	 */
	updateItem?: (item: T) => void;
	/**
	 * a function that converts an element after saving
	 * @param item - element
	 * @param response - request response
	 * @return Partial<T>
	 */
	transformAfterSave?: (item: T | undefined, response: T) => Partial<T>;
	/**
	 * init load condition
	 */
	skipInitLoad?: boolean;
	/**
	 * init error
	 */
	error?: string;
	/**
	 * defines the default value if element id < 0
	 */
	withoutAdd?: boolean;
	/**
	 * a function that converts an element after saving
	 * @param response - request response
	 * @param item - element
	 * @return number
	 */
	getIdAfterSave?: (response: T, data: T) => number;
	/**
	 * a function that handles the url
	 * @param response - request response
	 * @param item - element
	 * @return number
	 */
	handleUrlAfterSave?: (response: T, data: T, history) => void;
	/**
	 * function to be called after save
	 * @param item - saved item
	 * @param res - request response
	 */
	onSave?: (item: T, response?: T) => void;
	/**
	 * time to live (ms) for cached response at RequestProvider if cache is available
	 */
	ttl?: number;
}

export interface ItemProviderContextState<T extends WithDeleted> {
	/**
	 * stored element
	 */
	item: T;
	/**
	 * ItemProvider loading state
	 */
	loading: boolean;
	/**
	 * stored save or load error message
	 */
	error: string;
	/**
	 * type from props
	 */
	type: string;
	/**
	 * view mode
	 */
	readonly: boolean;
	/**
	 * Schema for checking element before saving.
	 *
	 * - Need to set a default value for the property that will be validated
	 */
	validationSchema?: AnySchema;
	/**
	 * loading state if item is undefined
	 */
	pageLoading: boolean;
	/**
	 * success message state
	 */
	message: string;
	/**
	 * a function that converts an element after saving
	 * @param response - request response
	 * @param item - element
	 * @return number
	 */
	getIdAfterSave: (response: T, data: T) => number;
	/**
	 * a function that converts an element after saving
	 * @param item - element
	 * @param response - request response
	 * @return Partial<T>
	 */
	transformAfterSave: (item: T | undefined, response: T) => Partial<T>;
}

interface ItemProviderContextActions<T> {
	/**
	 * load new item for ItemProvider
	 * @param params - load params
	 * @return Promise<T>
	 */
	load: (params?: BaseParams) => Promise<T>;
	/**
	 * send save request
	 * @param item - new item
	 * @param skipValidation - ignore validation or no. By default is undefined
	 * @return Promise<T>
	 */
	update: (item: T, skipValidation?: boolean) => Promise<T>;
	/**
	 * update stored item without request
	 * @param value - React.SetStateAction<T>
	 */
	setItem: (value: React.SetStateAction<T>) => void;
	/**
	 * sent delete item request
	 */
	deleteItem: () => void;
	/**
	 * update message state
	 * @param value - React.SetStateAction<T>
	 */
	setMessage: (value) => void;
	/**
	 * update error state
	 * @param error - error message
	 */
	setError: (error: string) => void;
}

export interface ItemProviderContext<T extends WithDeleted> {
	state: ItemProviderContextState<T>;
	actions: ItemProviderContextActions<T>;
}

export const createItemProviderContext = once(<T extends WithDeleted, >() => React.createContext({} as ItemProviderContext<T>));

/**
 * useItemProviderContext - get ItemProviderContext
 * @typeParam T - T Any {WithKey}
 * @param required - if true throw exception when context is empty
 * @returns ItemProviderContext<T>
 */
export const useItemProviderContext = <T extends WithDeleted, >(required = true) => {
	const context : ItemProviderContext<T> = React.useContext(createItemProviderContext<T>());

	if (required && isEmpty(context)) throw 'Need ItemProvider context!';

	return context;
};

/**
 * defaultGetIdAfterSave - get element id
 * @param response - request response
 * @param data - element
 * @returns res
 */
const defaultGetIdAfterSave = (response, data) => response.id as number;

/**
 * defaultHandleUrlAfterSave - get element id
 * @param response - request response
 * @param data - element
 * @param history
 */
const defaultHandleUrlAfterSave = (response, data, history) => {
	if (data.id < 0) {
		window.setTimeout(() => {
			history.replace({
				...location,
				pathname: location.pathname.replace('/-1', `/${response.id}`),
			});
		}, 0);
	}
};

/**
 * ItemProvider component.
 *
 * usage examples:
 *  - <ItemProvider type="someType">{React.ReactNode}</ItemProvider>
 *  - <ItemProvider type="someType">{(context) => React.ReactNode}</ItemProvider>
 *
 * @typeParam T - T Any {WithKey}
 * @param props - ItemProviderProps
 * @type {React.FC<ItemProviderProps>}
 * @returns React.ReactElement
 */

export const ItemProvider: <T extends WithDeleted>(p: ItemProviderProps<T>) => React.ReactElement<T> = <T extends WithDeleted, >(
	{
		item = undefined,
		type,
		loadRequest = type,
		saveRequest = type,
		children,
		validationSchema,
		clearForSubmit = (item) => item,
		onRequestError,
		onValidationError,
		readonly = true,
		onLoad,
		additionalParams = {},
		updateItem,
		transformAfterSave = (item, response) => item as T,
		id = -1,
		skipInitLoad = false,
		error: initError = '',
		add = {},
		withoutAdd = false,
		getIdAfterSave = defaultGetIdAfterSave,
		handleUrlAfterSave = defaultHandleUrlAfterSave,
		onSave,
		ttl,
	} : ItemProviderProps<T>,
) => {
	const ItemContext = createItemProviderContext<T>();

	const [_item, _setItem] = React.useState<T>(!withoutAdd && +id < 0 ? { id, ...add } as T : item as T);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState<string>(initError);
	const [message, setMessage] = React.useState<string>('');
	const [pageLoading, setPageLoading] = React.useState(false);
	const data = React.useRef<number>(skipInitLoad ? id : 0);
	const history = useHistory();
	const request = useRequest();
	const requestContext = useRequestProviderContext();
	const [abortController, setAbortController] = useAbortController();

	React.useEffect(() => {
		if ((!_item || +id !== +data.current) && +id >= 0) {
			setPageLoading(true);
			data.current = +id;
			load()
				.then(() => {
					setPageLoading(false);
				})
				.catch((error) => {
					if (typeof error === 'string' && error.includes('aborted')) {
						return item as T;
					}
					setPageLoading(false);
				});
		}
	}, [id]);

	React.useEffect(() => {
		if (skipInitLoad && _item && +id >= 0 && +id === _item.id && requestContext?.actions?.updateCache) {
			requestContext.actions.updateCache(
				loadRequest,
				{ ...additionalParams, id },
				item,
				ttl,
			);
		}
		return () => {
			abortController.abort();
		};
	}, []);

	const setItem = (value: React.SetStateAction<T>) => {
		_setItem((prev) => {
			const newItem = typeof value === 'function' ? value(prev) : value;
			data.current = newItem.id;
			updateItem && updateItem(newItem);
			return newItem;
		});
	};

	const load = (params?: BaseParams) => {
		setError('');

		return request<T>(
			loadRequest,
			{ ...additionalParams, ...params, id },
			() => setLoading(true),
			ttl,
			abortController.signal,
		)
			.then((res: T) => {
				setItem(res);
				setLoading(false);
				onLoad && onLoad(res);
				return res;
			}).catch((error) => {
				if (typeof error === 'string' && error.includes('aborted')) {
					throw error;
				}
				setLoading(false);

				onRequestError && onRequestError(error);
				setError(error);
				return item as T;
			});
	};

	const saveItem = (data: T) => {
		const item = { ...data, ...clearForSubmit(data) };

		setLoading(true);
		setError('');

		return request<T>(saveRequest, item)
			.then((response) => {
				setItem({ ...data, ...transformAfterSave(data, response), id: getIdAfterSave(response, data) });

				handleUrlAfterSave(response, data, history);
				onSave && onSave(item, response);
				return response;
			}).catch((error: string) => {
				onRequestError && onRequestError(error);
				setError(error);

				throw error;
			}).finally(() => setLoading(false));
	};

	const update = (item : T, skipValidation?: boolean) => {
		if (!item.deleted && validationSchema && !skipValidation) {
			return validationSchema?.validate(item, { abortEarly: false }).then(() => {
				return saveItem(item);
			}).catch((err) => {
				if (err.inner) {
					const er = {};
					for (let i = 0; i < err.inner.length; i++) {
						er[err.inner[i].path] = err.inner[i].errors[0];
					}

					setError(err.message);

					onValidationError && onValidationError(item, er, err);
				} else {
					throw err;
				}
			}) as Promise<T>;
		}

		return saveItem(item);
	};

	const deleteItem = () => {
		_item && saveItem({ ..._item, deleted: true });
	};

	const value: ItemProviderContext<T> = {
		state: {
			item: _item,
			loading,
			error,
			type,
			validationSchema,
			readonly,
			pageLoading,
			message,
			transformAfterSave,
			getIdAfterSave,
		},
		actions: {
			load,
			update,
			setItem,
			deleteItem,
			setMessage,
			setError,
		},
	};

	return (
		<ItemContext.Provider value={value}>
			{typeof children === 'function' ? children(value) : children}
		</ItemContext.Provider>
	);
};
