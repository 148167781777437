import React from 'react';

import { Field } from 'formik';

interface CustomInputProps {
	name: string;
	type?: string;
	placeholder?: string;
	label?: string;
	formikBag?: any;
	classNameWrap?: string;
	classNameInput?: string;
	component?: any;
	options?: any;
	stateSelected?: string | number;
	setStateSelected?: any;
	optionName1?: string;
	fieldValue?: string;
	setFieldValue?: any;
	optionName2?: string;
	innerIcon?: string | JSX.Element;
	removeSpace?: boolean;
	removeSpacesAll?: boolean;
	autocomplete?: null | 'off' | string;
	maxLength?: number;
	handleOnChange?: (e) => void;
	onBlur?: (val: any) => void;
	onChange?: (val: any) => void;
}

const CustomInput: React.FC<CustomInputProps> = ({
	type,
	name,
	label = '',
	placeholder = '',
	classNameWrap = 'custom-input',
	classNameInput = '',
	component,
	formikBag,
	innerIcon = null,
	removeSpace = false,
	removeSpacesAll = false,
	autocomplete = 'off',
	maxLength,
	handleOnChange = () => {},
	onBlur,
	onChange,
}) => {
	const textareaRef = React.useRef<HTMLTextAreaElement>(null);

	React.useEffect(() => {
		if (component === 'textarea' && maxLength && maxLength > 0 && textareaRef.current) {
			const handleInput = () => {
				const textarea = textareaRef.current;

				if (textarea) {
					textarea.style.height = 'auto';
					textarea.style.height = `${textarea.scrollHeight}px`;
				}
			};
			handleInput();
			textareaRef?.current?.addEventListener('input', handleInput);

			return () => {
				textareaRef?.current?.removeEventListener('input', handleInput);
			};
		}
	}, [component]);

	return (
		<div
			className={`${classNameWrap} ${
				formikBag.errors[name] && formikBag.touched[name] ? 'error' : ''
			} ${
				formikBag.touched[name] || formikBag.values[name] ? 'focused' : ''
			}`}
		>
			{label && <label htmlFor={name}>{label}</label>}
			<Field
				innerRef={textareaRef}
				as={component}
				type={type}
				name={name}
				placeholder={placeholder}
				id={name}
				maxLength={maxLength}
				autoComplete={autocomplete}
				className={classNameInput}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					if (onChange) {
						onChange(e.target.value);
					} else {
						formikBag.setFieldValue(
							name,
							removeSpace ? e.target.value.trim() : removeSpacesAll ? e.target.value.replace(' ', '') : e.target.value,
						);
						handleOnChange && handleOnChange(e);
					}
				}}
				onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
					formikBag.setFieldTouched(name, true);
					onBlur && onBlur(e.target.value);
				}}
			/>
			<div className="custom-input__inner-icon">{innerIcon}</div>
			{formikBag.errors[name] && formikBag.touched[name] && (
				<div className="custom-input__error">
					{formikBag.errors[name]}
				</div>
			)}
			<div className="custom-input__placeholder-top">{placeholder}</div>
		</div>
	);
};

export default CustomInput;
