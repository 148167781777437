import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import * as Yup from 'yup';

import { ItemEditor } from '@common/react/components/Core/ItemEditor/ItemEditor';
import { ItemProvider, useItemProviderContext } from '@common/react/components/Core/ItemProvider/ItemProvider';
import { Loading } from '@common/react/components/UI/Loading/Loading';
import { phoneRegexp } from '@common/react/utils/validationHelpers';
import { List } from '@common/typescript/objects/List';

import { rest } from '@app/components/Api';
import CustomRequestProvider from '@app/components/CustomRequestProvider/CustomRequestProvider';
import FormikField from '@app/components/Forms/CustomFormikField/CustomFormikField';
import { CustomPhoneControl } from '@app/components/Forms/CustomPhoneControl/CustomPhoneControl';
import CustomSelect from '@app/components/Forms/CustomSelect/CustomSelect';
import { numberBeautifyValidator, zipCodeValidator } from '@app/components/LocalCommon/Utils/Validators';
import { PetSpecies } from '@app/components/Pages/Area/AreaObjects';
import CustomInput from '@app/components/UI/CustomInput/CustomInput';

interface FormProps {
	onSubmit: () => void;
}

interface FormValues {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	zip: string;
	message: string;
	petName: string;
	petWeight: number | null;
	gender: number | null;
	petSpecies: number | null;
	captcha: string;
	id: number;
}

const beautifyValidator = (errorText: string, errorManual: string = '', maxLength: number = 255) => {
	return !errorManual ? Yup.string().required(errorText).max(maxLength) : Yup.string().email(errorManual).required(errorText);
};

const validationSchema = Yup.object({
	firstName: beautifyValidator('First Name is a required field'),
	lastName: beautifyValidator('Last Name is a required field'),
	email: beautifyValidator('Email is a required field', 'Email must be a valid Email'),
	phone: Yup.string().matches(phoneRegexp, 'Invalid phone number').required('Phone is a required field'),
	zip: zipCodeValidator(),
	petWeight: numberBeautifyValidator(
		"Pet's Weight must be a number",
		"Pet's Weight must be greater than zero",
		"Pet's Weight is a required field",
		"Pet's Weight must be a whole number (1lb or more, with no decimals)",
	),
	petSpecies: Yup.string().nullable().required("Pet's Species is a required field"),
	gender: Yup.string().nullable().required("Pet's Gender is a required field"),
	captcha: Yup.string().required('Please check the box to submit this form'),
});

const initialValues: FormValues = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	zip: '',
	message: '',
	petName: '',
	petWeight: null,
	petSpecies: null,
	gender: null,
	captcha: '',
	id: -1,
};

const GetInTouchFormEditor: React.FC<FormProps> = ({ onSubmit }) => {
	const context = useItemProviderContext<FormValues>();

	if (!context?.state) throw 'Error context';

	const { state: { loading } } = context;

	const [species, setSpecies] = React.useState<Array<PetSpecies>>([]);
	const [sent, setSent] = React.useState<boolean>(false);

	const gender = [
		{ value: 1, label: 'Male' },
		{ value: 2, label: 'Female' },
	];

	React.useEffect(() => {
		rest.get<List<PetSpecies>>('v1/petspecies')
			.then((res) => setSpecies(res.list));
	}, []);

	return !sent ? (
		<div className="get-in-touch__form">
			<ItemEditor<FormValues>
				formikProps={{
					initialValues,
					validationSchema,
				}}
				readonly={false}
				withButtons={false}
				afterSubmit={() => {
					onSubmit();
					setSent(true);
				}}
				customButtons={() => (
					<>
						{loading && <Loading />}
						<div className="text-center">
							<button type="submit" className="btn btn-primary" disabled={loading}>
								Send
							</button>
						</div>
					</>
				)}
				edit={(formikBag) => (
					<>
						<div className="form-group">
							<CustomInput name="firstName" placeholder="First Name*" formikBag={formikBag} />
							<CustomInput name="lastName" placeholder="Last Name*" formikBag={formikBag} />
							<CustomInput name="petName" placeholder="Pet's Name*" formikBag={formikBag} />
							<CustomSelect
								name="petSpecies"
								placeholder="Pet's Species*"
								onSelect={(value) => {
									formikBag.setFieldValue('petSpecies', value);
								}}
								onChange={(value) => {
									formikBag.setFieldValue('petSpecies', value);
								}}
								options={species}
								showSearch={false}
								onFocus={() => {}}
								transform
								formikBag={formikBag}
							/>
							<CustomSelect
								name="gender"
								placeholder="Gender*"
								onSelect={(value) => {
									formikBag.setFieldValue('gender', value);
								}}
								onChange={(value) => {
									formikBag.setFieldValue('gender', value);
								}}
								options={gender}
								showSearch={false}
								onFocus={() => {}}
								formikBag={formikBag}
							/>
							<CustomInput type="number" name="petWeight" placeholder="Pet's Weight*" formikBag={formikBag} innerIcon="Lbs" />
							<CustomInput name="email" placeholder="Email Address*" formikBag={formikBag} removeSpace />
							<div className="phone">
								<FormikField
									title="Phone Number"
									fieldName="phone"
									render={(fieldProps) => <CustomPhoneControl placeholder="Phone Number*" fieldProps={fieldProps} />}
								/>
							</div>
							<CustomInput name="zip" placeholder="US Zip Code*" formikBag={formikBag} />
						</div>
						<CustomInput name="message" placeholder="Tell us what is going on with your pet" formikBag={formikBag} />
						<div className="text">
							By providing a telephone number and submitting the form you are consenting to be contacted by SMS text message by Lap of Love.
							Message & data rates may apply. Reply STOP to opt out of further messaging.
						</div>
						<FormikField
							containerClassName="captcha"
							fieldName="captcha"
							render={() => (
								<ReCAPTCHA
									sitekey="6LezTMIcAAAAACaXvLNse5bmTJpwqs0Ezm_bhTrU"
									onChange={(value) => {
										if (value) {
											formikBag.setFieldValue('captcha', value);
										} else {
											formikBag.setFieldValue('captcha', '');
										}
									}}
									onExpired={() => formikBag.setFieldValue('captcha', 'expired')}
									hl="en"
								/>
							)}
						/>
					</>
				)}
			/>
		</div>
	) : null;
};

const GetInTouchForm: React.FC<FormProps> = ({ onSubmit }) => {
	return (
		<CustomRequestProvider requestMethod="POST">
			<ItemProvider
				id={-1}
				type="getInTouch"
				saveRequest="getInTouch"
			>
				<GetInTouchFormEditor onSubmit={onSubmit} />
			</ItemProvider>
		</CustomRequestProvider>
	);
};

export default GetInTouchForm;
