import { FooterMenuItem } from '@app/objects/CustomMenuItem';

const services: Array<FooterMenuItem> = [
	{
		path: '/our-services/veterinary-hospice',
		name: 'Veterinary Hospice',
	},
	{
		path: '/our-services/in-home-euthanasia',
		name: 'In-Home Euthanasia',
	},
	{
		path: '/our-services/aftercare',
		name: 'Aftercare',
	},
	{
		path: '/our-services/telehospice',
		name: 'Telehospice',
	},
	{
		path: '/our-services/pet-loss-support',
		name: 'Pet Loss Support',
	},
];

const resources: Array<FooterMenuItem> = [
	{
		path: '/blog',
		name: 'Blog',
	},
	{
		path: '/resource-center',
		name: 'Resource Center',
	},
	{
		path: '/how-will-i-know-it-is-time/Lap-of-Love-Quality-of-Life-Scale-2024.pdf',
		name: 'Quality-of-Life Scale',
		newTab: true,
	},
	{
		path: '/veterinary-resource-center',
		name: 'Veterinarians Login',
	},
];

const communityChildren: Array<FooterMenuItem> = [
	{
		path: '/pet-memorial',
		name: 'Pet Memorials',
	},
	{
		path: '/veterinarian-testimonials',
		name: 'Testimonials',
	},
	{
		path: '/angel-fund',
		name: 'Angel Fund',
	},
	{
		path: '/pet-loss-support-resources',
		name: 'Pet Loss Support',
	},
];

const aboutUs: Array<FooterMenuItem> = [
	{
		path: '/about-us/who-we-are',
		name: 'Who We Are',
	},
	{
		path: 'https://lapoflovejobs.workable.com',
		name: 'Join Our Team',
	},
	{
		path: '/about-us/student-externship',
		name: 'Student Externship',
	},
	{
		path: '/',
		name: 'Contact Us',
	},
	{
		path: '/media-kit.pdf',
		name: 'Media Kit',
	},
];

export const footerMenu: { [key: string]: Array<FooterMenuItem> } = {
	Services: services,
	Resources: resources,
	Community: communityChildren,
	'About Us': aboutUs,
};
