import React from 'react';

import '@app/scss/components/bannerGlobal.scss';

interface BannerProps {
	link?: string;
	btn?: boolean;
	textBtnOpt?: string;
	btnClosed?: boolean;
	children: React.ReactElement;
	onBannerClose?: () => void;
}

const BannerGlobal: React.FC<BannerProps> = ({
	btn = false,
	textBtnOpt = 'Accept',
	btnClosed = true,
	onBannerClose,
	children,
}) => {
	const [bannerVisible, setBannerVisible] = React.useState(true);
	const [bannerClosed, setBannerClosed] = React.useState(false);
	const closeBanner = (e) => {
		e.stopPropagation();
		setBannerVisible(false);
		setBannerClosed(true);

		if (onBannerClose) {
			onBannerClose();
		}
	};

	const [scrollPosition, setScrollPosition] = React.useState(0);

	const handleScroll = () => {
		const position = window.pageYOffset;
		setScrollPosition(position);
		position > 500 ? setBannerVisible(false) : !bannerClosed ? setBannerVisible(true) : null;
	};

	React.useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [bannerClosed]);

	return (
		<div className={bannerVisible ? 'banner-global' : 'banner-global banner-global__hidden'}>
			<div className="banner-global__content">{children}</div>

			<div>
				{btn && (
					<button className="btn btn-sm btn-primary btn-opt" onClick={closeBanner}>
						{textBtnOpt}
					</button>
				)}

				{btnClosed && (
					<button className="btn btn-icon btn-closed" onClick={closeBanner}>
						&times;
					</button>
				)}
			</div>
		</div>
	);
};

export default BannerGlobal;
