import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import '@common/react/scss/components/menu.scss';

import arrowIcon from '@app/images/arrow_circle.svg';
import { CustomMenuItem } from '@app/objects/CustomMenuItem';

interface MenuItemProps extends Omit<MenuProps, 'items'> {
	item: CustomMenuItem;
	isMobile: boolean;
	withChildren?: boolean;
	basePath?: string;
	pathKey?: string;
}

interface MenuProps {
	items: Array<CustomMenuItem>;
	isMobile: boolean;
	defaultOpen?: boolean;
}

const carrotIcon = require('@app/images/carrot.png');
const arrowIconActive = require('@app/images/arrow_circle_active.svg');

const SwitchItem = ({
	item,
	switchTab,
	withIcon,
	path,
	isActive,
	exact,
	index,
	currentIndex,
}) => {
	return (
		<div className={`menu-component__tab-item ${currentIndex === index ? 'menu-component__tab-item_active' : ''}`} style={{ display: 'flex' }}>
			<div onMouseEnter={() => switchTab(index)} className={`menu-component__tab-item-btn ${withIcon ? 'btn-text' : ''}`}>
				{item.name}
				{item.withIcon && (
					<img
						className={`arrow-icon ${currentIndex === index ? 'arrow-icon_active' : ''}`}
						src={currentIndex === index ? arrowIconActive : arrowIcon}
						alt="Arrow"
					/>
				)}
			</div>
			<div className="menu-component__tab-item-children" style={{ justifyContent: 'space-between' }}>
				{item.children?.map((list, listIndex) => (
					<div
						key={listIndex}
						className={`menu-component__tab-item-list ${item.name.toLowerCase()}`}
						style={{ display: 'flex', flexDirection: 'column' }}
					>
						{list.map((child, index) => {
							return child.path ? (
								!child.externalLink ? (
									<NavLink
										exact={child.exact}
										to={child.path}
										key={index}
										className="menu-component__item-link"
										activeClassName="menu-component__item-link_active"
										isActive={child.isActive}
									>
										{child.name}
									</NavLink>
								) : (
									<a
										key={index}
										href={child.path}
										target={child.newTab ? '_blank' : '_self'}
										className="menu-component__item-link"
										rel="noopener noreferrer"
									>
										{child.name}
									</a>
								)
							) : (
								<span key={index} className="menu-component__item-title">
									{child.name}
								</span>
							);
						})}
					</div>
				))}
			</div>
		</div>
	);
};

const Item: React.FC<MenuItemProps> = (props) => {
	const {
		item,
		withChildren,
		basePath,
		pathKey,
		isMobile,
	} = props;
	const location = useLocation();
	const [open, setOpen] = React.useState(props.defaultOpen || item.isOpen || false);

	const toggleMenu = (): void => {
		setOpen((prev) => !prev);
	};
	const stopPropagation = (event: React.SyntheticEvent) => event.stopPropagation();

	const condition = withChildren && item.children?.length;
	const path = item[pathKey || 'path'];
	const {
		exact = false,
		isActive,
		childrenFullWidth, withIcon,
		externalLink,
		newTab,
		switcher,
	} = item;

	// tslint:disable-next-line:max-line-length
	const className = `menu-component__item ${open && condition ? 'menu-component__item_open' : ''} ${
		condition ? 'menu-component__item_with-children' : ''
	} ${item.className || ''}`;
	const containerClassName = childrenFullWidth ? 'menu-component__item-children-container_full-width' : '';

	const [currentIndex, setCurrentIndex] = React.useState(0);

	const switchTab = (index) => {
		setCurrentIndex(index);
	};

	React.useEffect(() => {
		setOpen(false);
	}, [location.pathname]);

	return (
		<li className={className} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
			{path ? (
				!externalLink ? (
					<NavLink
						exact={exact}
						to={path}
						className={`menu-component__item-link ${withIcon ? 'btn-text' : ''}`}
						activeClassName="menu-component__item-link_active"
						isActive={isActive}
					>
						{item.name}
						{withIcon && <img className="arrow-icon" src={arrowIcon} alt="Arrow" />}
					</NavLink>
				) : (
					<a
						href={path}
						target={newTab ? '_blank' : '_self'}
						className={`menu-component__item-link ${withIcon ? 'btn-text' : ''}`}
						rel="noopener noreferrer"
					>
						{item.name}
						{withIcon && <img className="arrow-icon" src={arrowIcon} alt="Arrow" />}
					</a>
				)
			) : (
				<span className="menu-component__item-link" onClick={toggleMenu}>
					{item.name}
				</span>
			)}
			{condition && !switcher && (
				<>
					<span className="menu-component__item-carrot" onClick={toggleMenu}>
						<img
							className={`menu-component__item-carrot_${isMobile && open ? 'up' : 'down'}`}
							height={7}
							width={11}
							alt="carrot"
							src={carrotIcon}
						/>
					</span>
					<div
						className={`menu-component__item-children-container ${open ? 'isOpen' : ''} ${containerClassName}`}
						onClick={stopPropagation}
					>
						{item.withSwitcher && !isMobile ? (
							<div className="menu-component__tab-container">
								{item.children?.map((list, listIndex) => (
									<React.Fragment key={`list-${listIndex}`}>
										{list.map((child, index) => (
											<SwitchItem
												item={child}
												switchTab={switchTab}
												withIcon={withIcon}
												path={path}
												isActive={isActive}
												exact={exact}
												key={index}
												index={index}
												currentIndex={currentIndex}
											/>
										))}
									</React.Fragment>
								))}
							</div>
						) : (
							item.children?.map((list, listIndex) => (
								<ul className="menu-component__children" key={listIndex} style={{ width: '50%' }}>
									{list.map((child, index) => (
										<Item item={child} key={index} withChildren={withChildren} pathKey={pathKey} isMobile={isMobile} />
									))}
								</ul>
							))
						)}
					</div>
				</>
			)}
		</li>
	);
};

const CustomMenu: React.FC<MenuProps> = ({ items, defaultOpen, isMobile }) => {
	const menuItems = items || [];

	return (
		<ul className="menu-component">
			{menuItems.map((item, index) => (
				<Item
					defaultOpen={defaultOpen}
					key={index}
					item={item}
					withChildren={!!item.children}
					isMobile={isMobile}
				/>
			))}
		</ul>
	);
};

export default CustomMenu;
