import React from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = (props) => {
	const location = useLocation();

	React.useEffect(() => {
		window.scrollTo(0, 0);
		document.querySelector('#react-app')?.scrollTo(0, 0);
	}, [location]);

	return null;
};

export default ScrollToTop;
