import React from 'react';
import { withRouter } from 'react-router-dom';

import * as Sentry from '@sentry/browser';

import { ErrorBoundary } from '@common/react/components/UI/ErrorBoundary/ErrorBoundary';

import '@app/scss/components/error.scss';

class SentryErrorBoundaryInner extends ErrorBoundary {
	componentDidCatch(error, errorInfo) {
		this.setState({
			message: (
				<>
					<h2>Oops, something went wrong.</h2>
					<h3>Try to refresh this page or feel free to contact us if the problem persists.</h3>
				</>
			),
		});
		super.componentDidCatch(error, errorInfo);
		Sentry.captureException(error);
	}
}

export const SentryErrorBoundary = withRouter(SentryErrorBoundaryInner);
