/**
 * ## ItemEditor.tsx ##
 * This file contains ItemEditor component
 * @packageDocumentation
 * */

import React from 'react';

import {
	Form, Formik, FormikProps, FormikConfig,
} from 'formik';

import { WithDeleted } from '@common/typescript/objects/WithDeleted';
import { Loading } from '@common/react/components/UI/Loading/Loading';
import Button from '@common/react/components/Forms/Button';
import {
	useItemProviderContext,
	ItemProviderContextState,
} from '@common/react/components/Core/ItemProvider/ItemProvider';
import Loader from '@common/react/components/Core/LoadingProvider/Loader';
import FormikRef from '@common/react/components/Core/ItemEditor/FormikRef';

interface State<T extends WithDeleted> extends ItemProviderContextState<T> {
	success?: boolean;
}

/**
 * This is the description of the interface. Requires ItemProvider wrapper
 *
 * @interface ItemEditorProps
 * @typeParam T - T Any WithDeleted entity
 */
interface ItemEditorProps<T extends WithDeleted> {
	/**
	 * render function in edit mode
	 * @param formikBag - formik data object. FormikProps<T>
	 * @param deleteItem - function from the ItemProvider context. Submit a request to remove an item
	 * @param state - itemProvider state,
	 * @param toggleReadonly - function changing viewing mode
	 * @return React.ReactNode
	 */
	edit: (formikBag: FormikProps<T>, deleteItem: () => void, state: State<T>, toggleReadonly: () => void) => React.ReactNode;
	/**
	 * render function in view mode
	 * @param item - item from ItemProvider
	 * @param toggleReadonly - function changing viewing mode
	 * @return React.ReactNode
	 */
	view?: (item: T, toggleReadonly: () => void) => React.ReactNode;
	/**
	 * function that determines the initial value for the form
	 * @param item - item from ItemProvider
	 */
	getInitialValues?: (item: T) => any;
	/**
	 * element shown when loading. Default <Loader defaultLoader={<Loading/>}/>
	 */
	loadingNode?: React.ReactNode;
	/**
	 * link to get formikBag outside the form
	 */
	formRef?: any;
	/**
	 * formik properties. It is possible to overwrite values such as initialValues, onSubmit, validationSchema
	 */
	formikProps?: Partial<FormikConfig<T>>;
	/**
	 * callback after item is saved
	 * - For example: make some changes at state
	 * @param item - saved item from ItemProvider
	 */
	afterSubmit?: (item: T) => void;
	/**
	 * callback before send request
	 * - For example. You can show a modal confirmation before sending the request
	 * @param values - current form values
	 * @param actions - form actions
	 * @param submit - item save function
	 */
	beforeSubmit?: (values: T, actions, submit: () => void) => void;
	/**
	 * if true and customButtons are not defined, default buttons will be displayed
	 */
	withButtons?: boolean;
	/**
	 * function to handle cancel button click. If not defined, the cancel button will not be displayed
	 */
	onCancel?: () => void;
	/**
	 * function to show custom buttons
	 * @param item - item from ItemProvider
	 * @param formikBag - formik data object. FormikProps<T>
	 * @return React.ReactNode
	 */
	customButtons?: (item: T, formikBag: FormikProps<T>) => React.ReactNode;
	/**
	 * the time during which success messages will be displayed. Default 5000 ms
	 */
	showMessageDuration?: number;
	/**
	 * by default 'Successfully saved'
	 */
	successMessage?: string;
	/**
	 * determines whether the form values need to be reset after saving. Default true
	 */
	resetFormAfterSubmit?: boolean;
	/**
	 * determines whether error or success messages should be shown. Default true
	 */
	showMessages?: boolean;
	/**
	 * readonly mode flag. Default value get from context
	 */
	readonly?: boolean;
}

const ItemEditorMessage: React.FC<{message: string}> = ({ message }) => {
	const ref = React.useRef<HTMLDivElement>(null);
	React.useEffect(() => {
		if (message) {
			ref.current?.scrollIntoView({ block: 'center', behavior: 'auto' });
		}
	}, [message]);

	return <>
		{message ? <div ref={ref} className="alert alert-success">{message}</div> : null}
	</>;
};

/**
 * ItemEditor component.
 *
 * @typeParam T - T Any {WithDeleted}
 * @param props - ItemEditorProps
 * @type {React.FC<ItemEditorProps>}
 * @returns React.ReactNode
 */
export const ItemEditor: <T extends WithDeleted>(p: ItemEditorProps<T>) => React.ReactElement<T> = <T extends WithDeleted>(props) => {
	const context = useItemProviderContext<T>();

	if (!context.state) throw 'Need ItemProvider context!';

	const {
		state: {
			item, loading, pageLoading, readonly: readonlyContext, error, validationSchema, type, message, transformAfterSave, getIdAfterSave,
		},
		actions: {
			update, deleteItem, setMessage, setError,
		},
	} = context;
	const readonlyProps = props.readonly;

	const [readonly, setReadonly] = React.useState<boolean>(readonlyProps ?? readonlyContext);
	const {
		getInitialValues = (item) => item,
		loadingNode = <Loader defaultLoader={<Loading />} />,
		formRef,
		formikProps,
		afterSubmit,
		view = () => {
			return null;
		},
		edit,
		withButtons,
		onCancel,
		customButtons,
		resetFormAfterSubmit = true,
		showMessages = true,
		beforeSubmit = null,
		showMessageDuration = 5000,
		successMessage = 'Successfully saved',
	} = props;

	React.useEffect(() => {
		setReadonly(readonlyProps ?? readonlyContext);
	}, [readonlyProps ?? readonlyContext]);

	const handleSubmit = (values: T, actions) => {
		const submit = () => update(values, true)
			.then((res) => {
				setMessage(successMessage);
				hideSuccess();
				setTimeout(() => {
					const newValues = { ...transformAfterSave(values, res), id: getIdAfterSave(res, values) };
					resetFormAfterSubmit && actions?.setValues(getInitialValues(newValues));
					afterSubmit && afterSubmit(newValues);
				}, 0);
			});

		beforeSubmit == null ? submit() : beforeSubmit(values, actions, submit);
	};

	const toggleReadonly = () => {
		setReadonly((prev) => !prev);
	};

	const hideSuccess = () => {
		setTimeout(() => {
			setMessage('');
		}, showMessageDuration < 500 ? 500 : showMessageDuration);
	};

	if (pageLoading || !item) {
		return loadingNode;
	}

	if (readonly) {
		return view(item, toggleReadonly);
	}

	return <Formik
		onSubmit={handleSubmit}
		validationSchema={validationSchema}
		{...formikProps}
		initialValues={formikProps?.initialValues === undefined ? getInitialValues(item) : formikProps?.initialValues}
		validate={(values) => {
			const obj = new Proxy(values, {
				get: (target, prop) => {
					if (typeof prop === 'string' && !prop.includes('$')) {
						if (target && !(prop in target) && prop !== 'then' && prop !== 'catch') {
							setError(`${prop} property is missing from Item`);
						}
					}
					// eslint-disable-next-line
					// @ts-ignore
					return target[prop];
				},
			});
			return formikProps?.validate?.(obj) || validationSchema?.validate(obj, { abortEarly: false })
				.then(() => undefined)
				.catch((err) => {
					const obj: any = {};
					if (typeof err === 'string') {
						return err;
					}
					Object.keys(err)
						.filter((key) => err[key] !== 'ValidationError')
						.forEach((key) => {
							if (err[key]) obj[key] = err[key];
						});
					return obj;
				});
		}}
	>
		{(formikBag: FormikProps<T>) => <Form id={`${type}-editor-form`}>
			<FormikRef formikRef={formRef} formikBug={formikBag} />
			{edit(formikBag, deleteItem, { ...context.state, success: !!message }, toggleReadonly)}
			{customButtons
				? customButtons(item, formikBag)
				: withButtons && <div className="text-center form-group">
					<Button isLoading={loading}>Save</Button>
					{onCancel && <button type="button" className="btn btn-danger" onClick={onCancel}>Cancel</button>}
				</div>
			}
			{showMessages && <>
				<ItemEditorMessage message={message} />
				{error ? <div className="alert alert-danger">{error}</div> : ''}
			</>}
		</Form>
		}
	</Formik>;
};
