import { CustomMenuItem } from '@app/objects/CustomMenuItem';

const servicesChildren: Array<CustomMenuItem> = [
	{
		path: '/our-services/veterinary-hospice',
		name: 'Veterinary Hospice',
	},
	{
		path: '/our-services/in-home-euthanasia',
		name: 'In-Home Euthanasia',
	},
	{
		path: '/our-services/aftercare',
		name: 'Cremation & Aftercare',
	},
	{
		path: '/our-services/telehospice',
		name: 'Telehospice',
	},
	{
		path: '/our-services/pet-loss-support',
		name: 'Pet Loss Support',
	},
	{
		path: 'https://info.lapoflove.com/keepsakes',
		name: 'Pet Memorial Keepsakes',
		externalLink: true,
		newTab: true,
	},
];

const qualityOfLifeChildren: Array<CustomMenuItem> = [
	{
		path: '/quality-of-life-assessment',
		name: 'Quality-of-Life Assessment',
	},
	{
		path: '/how-will-i-know-it-is-time',
		name: "How Will I Know It's Time",
	},
	{
		path: '/how-will-i-know-it-is-time/Lap-of-Love-Quality-of-Life-Scale-2024.pdf',
		name: 'Quality-of-Life Scale',
		externalLink: true,
		newTab: true,
	},
	{
		path: '/how-will-i-know-it-is-time/interactive-quality-of-life-assessment-and-diary.pdf',
		name: 'Quality-of-Life Daily Assessment',
		externalLink: true,
		newTab: true,
	},
];

const communityChildren: Array<CustomMenuItem> = [
	{
		path: '/pet-memorial',
		name: 'Pet Memorials',
	},
	{
		path: '/veterinarian-testimonials',
		name: 'Testimonials',
	},
	{
		path: '/angel-fund',
		name: 'Angel Fund',
	},
	{
		path: '/pet-loss-support-resources',
		name: 'Pet Loss Resources',
	},
];

const aboutUsLeft: Array<CustomMenuItem> = [
	{
		path: '',
		name: 'About Us',
	},
	{
		path: '/about-us/who-we-are',
		name: 'Who We Are',
	},
	{
		path: 'https://lapoflovejobs.workable.com',
		name: 'Join Our Team',
		externalLink: true,
		newTab: true,
	},
	{
		path: '/about-us/support-center-team',
		name: 'Support Center Team',
	},
	{
		path: '/about-us/student-externship',
		name: 'Student Externship',
	},
	{
		path: '/in-the-news/press-clippings',
		name: 'In the News',
	},
];

const aboutUsRight: Array<CustomMenuItem> = [
	{
		path: '',
		name: 'Join Our Team',
	},
	{
		path: 'https://apply.workable.com/lapoflovejobs/',
		name: 'Veterinarian',
		externalLink: true,
		newTab: true,
	},
	{
		path: '/careers/become-a-care-coordinator',
		name: 'Care Coordinator',
	},
];

const educatResources: Array<CustomMenuItem> = [
	{
		path: '',
		name: 'Educational Resources',
	},
	{
		path: '/resource-center/common-diseases-in-cats',
		name: 'Common Cat Diseases',
	},
	{
		path: '/resource-center/common-diseases-in-dogs',
		name: 'Common Dog Diseases',
	},
	{
		path: '/resource-center/symptom-management',
		name: 'Symptom Management',
	},
];

const educatResourcesMobile: Array<CustomMenuItem> = [
	{
		path: '/resource-center/common-diseases-in-cats',
		name: 'Common Cat Diseases',
	},
	{
		path: '/resource-center/common-diseases-in-dogs',
		name: 'Common Dog Diseases',
	},
	{
		path: '/resource-center/symptom-management',
		name: 'Symptom Management',
	},
];

const addResources: Array<CustomMenuItem> = [
	{
		path: '',
		name: 'Additional Resources',
	},
	{
		path: '/blog',
		name: 'Blog',
	},
	{
		path: '/newsletter',
		name: 'Newsletter',
	},
	{
		path: '/videos',
		name: 'Videos',
	},
	{
		path: '/faq',
		name: 'FAQ',
	},
];

const addResourcesMobile: Array<CustomMenuItem> = [
	{
		path: '/blog',
		name: 'Blog',
	},
	{
		path: '/newsletter',
		name: 'Newsletter',
	},
	{
		path: '/videos',
		name: 'Videos',
	},
	{
		path: '/faq',
		name: 'FAQ',
	},
];

const supportResources: Array<CustomMenuItem> = [
	{
		path: '',
		name: 'Support Resources',
	},
	{
		path: '/how-will-i-know-it-is-time',
		name: "How Will I Know It's Time",
	},
	{
		path: '/quality-of-life-calendar.pdf',
		name: 'Pet Quality-of-Life Calendar',
		externalLink: true,
		newTab: true,
	},
	{
		path: '/pet-loss-support-resources',
		name: 'Pet Loss and Grief',
	},
];

const supportResourcesMobile: Array<CustomMenuItem> = [
	{
		path: '/how-will-i-know-it-is-time',
		name: "How Will I Know It's Time",
	},
	{
		path: '/quality-of-life-calendar.pdf',
		name: 'Pet Quality of Life Calendar',
		externalLink: true,
		newTab: true,
	},
	{
		path: '/pet-loss-support-resources',
		name: 'Pet Loss and Grief',
	},
];

const vetAccess: Array<CustomMenuItem> = [
	{
		path: '',
		name: 'Veterinary Access',
	},
	{
		path: '/veterinary-resource-center',
		name: 'Login / Sign Up',
	},
];

const vetAccessMobile: Array<CustomMenuItem> = [
	{
		path: '/veterinary-resource-center',
		name: 'Login / Sign Up',
	},
];

const vetResources: Array<CustomMenuItem> = [
	{
		path: '',
		name: 'Veterinary Resources',
	},
	{
		path: '/veterinary-resources/hospice-pain-management',
		name: 'Hospice Pain Management',
	},
	{
		path: '/veterinary-resources/euthanasia-sedation-protocols',
		name: 'Euthanasia Sedation Protocols',
	},
	{
		path: '/veterinary-resources/euthanasia-best-practices',
		name: 'Euthanasia Best Practices',
	},
	{
		path: '/veterinary-resources/geriatric-clinic-tips',
		name: 'Geriatric Clinic Tips',
	},
	{
		path: '/veterinary-resources/faq',
		name: 'FAQ',
	},
];

const vetResourcesMobile: Array<CustomMenuItem> = [
	{
		path: '/veterinary-resources/hospice-pain-management',
		name: 'Hospice Pain Management',
	},
	{
		path: '/veterinary-resources/euthanasia-sedation-protocols',
		name: 'Euthanasia Sedation Protocols',
	},
	{
		path: '/veterinary-resources/euthanasia-best-practices',
		name: 'Euthanasia Best Practices',
	},
	{
		path: '/veterinary-resources/geriatric-clinic-tips',
		name: 'Geriatric Clinic Tips',
	},
	{
		path: '/veterinary-resources/faq',
		name: 'FAQ',
	},
];

const resourcesArrows: Array<CustomMenuItem> = [
	{
		path: '',
		name: 'Family Resource Center',
		withIcon: true,
		switcher: true,
		children: [educatResources, supportResources, addResources],
	},
	{
		path: '',
		name: 'Veterinary Resource Center',
		withIcon: true,
		switcher: true,
		children: [vetAccess],
	},
];

const resourcesEdu: Array<CustomMenuItem> = [
	{
		path: '',
		name: 'Quality of Life Resources',
	},
	{
		path: '/quality-of-life-assessment',
		name: 'Quality of Life Assessment',
	},
	{
		path: '/how-will-i-know-it-is-time',
		name: 'How will I know when it’s time?',
	},
	{
		path: '/',
		name: 'The Pet Hospice Journal',
	},
	{
		path: '/quality-of-life/grey-muzzle-app',
		name: 'Grey Muzzle Quality of Life App',
	},
];

export const menu: Array<CustomMenuItem> = [
	{
		path: '',
		name: 'Services',
		children: [servicesChildren],
	},
	{
		path: '',
		name: 'Resources',
		children: [resourcesArrows],
		childrenFullWidth: true,
		withSwitcher: true,
	},
	{
		path: '',
		name: 'Quality of Life',
		children: [qualityOfLifeChildren],
	},
	{
		path: '',
		name: 'Community',
		children: [communityChildren],
	},
	{
		path: '',
		name: 'About Us',
		children: [aboutUsLeft],
	},
];

export const mobileMenu: Array<CustomMenuItem> = [
	{
		path: '',
		name: 'Services',
		children: [servicesChildren],
	},
	{
		path: '',
		name: 'Family Resource Center',
		children: [educatResourcesMobile, supportResourcesMobile, addResourcesMobile],
		childrenFullWidth: true,
		withSwitcher: true,
	},
	{
		path: '',
		name: 'Veterinary Resource Center',
		children: [vetAccessMobile],
		childrenFullWidth: true,
		withSwitcher: true,
	},
	{
		path: '',
		name: 'Quality of Life',
		children: [qualityOfLifeChildren],
	},
	{
		path: '',
		name: 'Community',
		children: [communityChildren],
	},
	{
		path: '',
		name: 'About Us',
		children: [aboutUsLeft.slice(1), aboutUsRight],
	},
];
