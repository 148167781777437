import * as Yup from 'yup';

export const stringBeautifyValidator = (errorText: string = 'Field is required', maxLength: number = 32767) => {
	return Yup.string().required(errorText).max(maxLength);
};

export const stringBeautifyLengthValidator = (errorText: string = 'Field is required', errLength: string, lenMin: number) => {
	return Yup.string()
		.required(errorText)
		.test('len', errLength, (val) => val.length >= lenMin);
};

export const numberBeautifyValidator = (
	typeError: string = 'Value must be a number',
	positive = 'Value must be greater than zero',
	required = 'Field is required',
	integer = 'Value must be a whole number',
) => {
	return Yup
		.number()
		.typeError(typeError)
		.positive(positive)
		.integer(integer)
		.required(required);
};

export const validateFile = (data, field, maxFile, formatErrorCapture) => {
	const errorFormat = formatErrorCapture;
	const errorSize = 'Image max file size is 10MB - Please upload a smaller image';
	data.size > maxFile ? field.form.setFieldError('filePath', errorSize) : field.form.setFieldError('filePath', errorFormat);
};

export const zipCodeValidator = () => {
	return Yup.string()
		.nullable()
		.required('Zip Code is a required field')
		.matches(/^[0-9]+$/, 'Only US Zip Codes are supported')
		.min(5, 'Zip Code must be exactly 5 digits')
		.max(5, 'Zip Code must be exactly 5 digits');
};
