declare const window: Window & { dataLayer: Array<Record<string, unknown>> };

export function pushEvent(event: string, data?: object) {
	window.dataLayer.push({
		event,
		...data,
	});
}

export function pushSocialShare(socialNetwork: 'Facebook' | 'Twitter' | 'LinkedIn' | 'Email', socialTarget: string) {
	pushEvent('socialShare', {
		socialNetwork,
		socialTarget,
	});
}

export function pushIconClick(icon: string) {
	pushEvent('iconClick', {
		icon,
	});
}

export function pushFileDownload(fileName: string) {
	pushEvent('files', {
		name: fileName,
	});
}
