import React from 'react';

import { Select } from 'antd';
import { Field, FormikProps } from 'formik';

import { ListOption } from '@app/objects/SidebarProps';

interface CustomSelectProps {
	placeholder?: string;
	options: any;
	onSelect: any;
	onChange: (value: string, option: any) => void;
	showSearch: boolean;
	name: string;
	onFocus: () => void;
	onSearch?: any;
	transform?: boolean;
	formikBag: FormikProps<any>;
	parentContainer?: string;
	disabled?: boolean;
	annotation?: string;
	placeholderLabel?: string;
	value?: string;
	showArrow?: boolean;
	open?: any;
	popupClassName?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
	placeholder = 'Please make a selection',
	options,
	onSelect,
	onChange,
	showSearch,
	name,
	onSearch,
	onFocus,
	transform = false,
	formikBag,
	parentContainer,
	disabled = false,
	annotation,
	placeholderLabel = '',
	value,
	showArrow = true,
	open,
	popupClassName,
}) => {
	const listOptions: Array<ListOption> = [];
	const transformOptions = () => {
		transform && (
			options.map((item) => {
				listOptions.push({ value: item.id ? item.id : item.name, label: item.name ? item.name : item.label });
			})
		);
	};
	transformOptions();

	const filterOption = (input: string, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	const [isOpen, setIsOpen] = React.useState<boolean | undefined>(open !== undefined ? open : undefined);

	React.useEffect(() => {
		setIsOpen(open);
	}, [open]);

	React.useEffect(() => {
		if (listOptions.length) {
			listOptions.find((item) => item.value === formikBag.values[name])?.label === undefined
				&& formikBag.setFieldValue(name, null);
		}
	}, [listOptions.length]);

	return (
		<div
			className={!annotation ? 'search-field search-field_auto custom-select' : 'search-field search-field_auto custom-select with-annotation'}
		>
			{annotation && <div className="search-field__annotation">{annotation}</div>}
			<Field
				name={name}
			>
				{(field) => {
					return (
						<div
							className={`custom-field ${
								field.form.errors[name] && field.form.touched[name] ? 'error' : ''
							} ${
								typeof field.form.values[name] === 'number'
								|| field.form.values[name]?.length
								|| placeholderLabel?.length ? 'focused' : ''
							}`}
						>
							{placeholderLabel ? (
								<div className="custom-field__placeholder-label">{placeholderLabel}</div>
							) : (
								<div className="custom-field__placeholder-top">{placeholder}</div>
							)}
							<Select
								showSearch={showSearch}
								placeholder={placeholder}
								options={listOptions.length ? listOptions : options}
								optionFilterProp="label"
								onSelect={onSelect}
								onChange={onChange}
								onSearch={showSearch && onSearch && onSearch()}
								filterOption={filterOption}
								onFocus={() => {
									showArrow ? setIsOpen(undefined) : setIsOpen(false);

									if (onFocus) {
										onFocus();
									}
								}}
								className={!showArrow ? 'noShowArrow' : ''}
								popupClassName={`custom-field-dropdown ${popupClassName || ''}`}
								onBlur={() => field.form.setFieldTouched(name, true)}
								open={isOpen}
								getPopupContainer={parentContainer ? () => document.querySelector(parentContainer)! : undefined}
								disabled={disabled}
								value={value || listOptions.length
									? listOptions.find((item) => item.value === formikBag.values[name])?.label || undefined
									: options.find((item) => item.value === formikBag.values[name])?.label || undefined
								}
								allowClear={!showArrow}
							/>
							{field.form.errors[name] && field.form.touched[name] ? (
								<div className="custom-input__error">
									{field.form.errors[name]}
								</div>
							) : null}
						</div>
					);
				}}
			</Field>
		</div>
	);
};

export default CustomSelect;
