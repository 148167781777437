import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RequestProviderProps, ResponseError, createRequestProviderContext } from '@common/react/components/RequestProvider/RequestProvider';
import { BaseParams } from '@common/react/objects/BaseParams';
import { BaseUser } from '@common/react/objects/BaseUser';
import { BaseApplicationState } from '@common/react/store';

interface CustomRequestProviderProps extends RequestProviderProps {
	requestMethod?: 'GET' | 'POST';
	version?: string;
}

const generateQueryParams = (params: BaseParams): string => {
	const newParams = params;
	delete newParams.current;
	delete newParams.page;
	delete newParams.pageSize;
	delete newParams.showSizeChanger;
	delete newParams.id;

	const arrParams: Array<string> = Object.entries(newParams).map((arr) => {
		const [key, value] = arr;
		return `${key}=${value}`;
	});

	if (arrParams.length) {
		return `?${arrParams.join('&')}`;
	}
	return '';
};

const CustomRequestProvider: React.FC<CustomRequestProviderProps> = ({
	children,
	ttl: defaultTtl = 0,
	requestMethod = 'GET',
	version = 'v1',
}) => {
	const ItemContext = createRequestProviderContext();

	const session = useSelector((state: BaseApplicationState<BaseUser>) => state.login.session, shallowEqual);
	const history = useHistory();

	const request = React.useMemo(() => {
		return <T, >(
			type: string,
			params: BaseParams = {},
			beforeRequest,
			ttl = defaultTtl,
			signal?: AbortSignal,
		): Promise<T> => {
			beforeRequest && beforeRequest();

			return fetch(`api/${version}/${type}${requestMethod === 'GET' ? generateQueryParams(params) : ''}`, {
				credentials: 'same-origin',
				method: requestMethod,
				headers: {
					'Content-type': 'application/json; charset=utf-8',
					Cookie: `session=${session || ''}`,
				},
				...(requestMethod === 'POST' && {
					body: JSON.stringify(params),
				}),
				signal,
			})
				.then((response: any) => response.json())
				.then((data) => {
					if (!data) {
						throw data as any;
					}
					return data.data ? (data.data as T) : (data as any);
				})
				.catch((error: ResponseError) => {
					throw error.message as string;
				});
		};
	}, [session, history.location]);

	const value = {
		state: {
			request,
		},
	};

	return (
		<ItemContext.Provider value={value}>
			{children}
		</ItemContext.Provider>
	);
};

export default CustomRequestProvider;
