import React from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import Search, { SearchProps } from 'antd/lib/input/Search';

import { Area } from '@app/objects/Area';

interface ComponentProps extends SearchProps {
	icon?: string;
	iconLocation?: boolean;
	widthBorder?: string;
	setSearchActive?: any;
	setSearch?: (value: string) => void;
	searchBody?: string;
	setValue?: (string) => void;
	tab?: string;
	autoFocus?: boolean;
	newURLAfterSearch?: boolean;
	searchZipCode?: boolean;
}

const searchIcon = require('@app/images/search_white.svg');
const locationIcon = require('@app/images/pin.svg');

const SearchField = React.forwardRef<typeof Search, ComponentProps>((props, ref) => {
	const [areas, setArea] = React.useState<Array<Area>>([]);
	const [redirectUrl, setRedirect] = React.useState('');
	const [checkValidInput, setCheckValidInput] = React.useState<boolean>(true);
	const widthBorder = props.widthBorder || '1';
	const newURLAfterSearch = props.newURLAfterSearch ?? true;
	const searchZipCode = props.searchZipCode ?? true;

	const inputRef: any = React.useRef() as React.RefObject<any>;

	const history = useHistory();

	const enterButton = (
		<button type="submit" className="search-field-button">
			<img src={props.icon || searchIcon} alt="Search Icon" />
		</button>
	);

	const handleOnSearch = (value) => {
		props.setSearch && props.setSearch(value.trim());
		newURLAfterSearch && history.push(`${props.searchBody || ''}?query=${value.trim()}`);
	};

	React.useEffect(() => {
		props.autoFocus ? inputRef.current && inputRef.current.focus() : null;
	}, [props.tab]);

	return (
		<div className="search-field-wrap">
			{redirectUrl && <Redirect to={redirectUrl} />}

			<Search
				ref={inputRef}
				placeholder={props.placeholder || 'Search'}
				className={`search-field search-field--border-${widthBorder} ${checkValidInput ? '' : 'search-field--border-error'}`}
				onSearch={(value) => {
					if (searchZipCode) {
						if (!isNaN(+value) && value.match(/^([0-9]{5})$/)) {
							handleOnSearch(value);
						}
						props.setSearchActive ? props.setSearchActive(false) : null;
					} else {
						handleOnSearch(value);
					}
				}}
				style={{ width: 200 }}
				onChange={(e) => {
					if (searchZipCode) {
						if (!isNaN(+e.currentTarget.value) && e.currentTarget.value.match(/^([0-9]{5})$/)) {
							props.setValue && props.setValue(e.currentTarget.value);
							setCheckValidInput(true);
						} else {
							setCheckValidInput(false);
						}
					} else {
						props.setValue && props.setValue(e.currentTarget.value);
						setCheckValidInput(true);
					}
				}}
				enterButton={enterButton}
				value={props.value}
				prefix={props.iconLocation ? <img src={props.iconLocation && locationIcon} alt="pin" /> : ''}
			/>
		</div>
	);
});

export default SearchField;
