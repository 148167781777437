import React from 'react';

import { Route, Switch } from 'react-router-dom';

import loadable from '@loadable/component';

import { loadableDelay, params } from '@common/react/loadable/loadableSettings';

import Layout from '@app/components/Layouts/Layout';
import LayoutCut from '@app/components/Layouts/LayoutCut';
import LayoutFamilyResources from '@app/components/Layouts/LayoutFamilyResources';
import LayoutShort from '@app/components/Layouts/LayoutShort';
import LayoutWrapper from '@app/components/Layouts/LayoutWrapper';

const Home = loadable(() => loadableDelay(import(/* webpackChunkName: "HomePage" */ '@app/components/Pages/Home/Home')), params);
const VeterinaryHospice = loadable(
	() => loadableDelay(import(/* webpackChunkName: "VeterinaryHospicePage" */ '@app/components/Pages/Services/VeterinaryHospice/VeterinaryHospice')),
	params,
);
const Euthanasia = loadable(
	() => loadableDelay(import(/* webpackChunkName: "EuthanasiaPage" */ '@app/components/Pages/Services/Euthanasia/Euthanasia')),
	params,
);
const Aftercare = loadable(
	() => loadableDelay(import(/* webpackChunkName: "AftercarePage" */ '@app/components/Pages/Services/Aftercare/Aftercare')),
	params,
);
const Burial = loadable(
	() => loadableDelay(import(/* webpackChunkName: "BurialPage" */ '@app/components/Pages/Services/Aftercare/Burial/Burial')),
	params,
);
const Aquamation = loadable(
	() => loadableDelay(import(/* webpackChunkName: "AquamationPage" */ '@app/components/Pages/Services/Aftercare/Aquamation/Aquamation')),
	params,
);
const Teleadvice = loadable(
	() => loadableDelay(import(/* webpackChunkName: "TeleadvicePage" */ '@app/components/Pages/Services/Teleadvice/Teleadvice')),
	params,
);
const PetLossSupportGroup = loadable(
	() => loadableDelay(import(/* webpackChunkName: "PetLossSupportGroup" */ '@app/components/Pages/Services/PetLossSupportGroup/PetLossSupportGroup')),
	params,
);
const AngelFund = loadable(() => loadableDelay(import(/* webpackChunkName: "AngelFundPage" */ '@app/components/Pages/AngelFund/AngelFund')), params);
const CatsAnxiety = loadable(
	() => loadableDelay(import(/* webpackChunkName: "CatsAnxietyPage" */ '@app/components/Pages/Resources/FamilyResources/ResourcePage')),
	params,
);
const QualityOfLife = loadable(
	() => loadableDelay(import(/* webpackChunkName: "QualityOfLifePage" */ '@app/components/Pages/QualityOfLife/QualityOfLife')),
	params,
);
const Newsletter = loadable(() => loadableDelay(import(/* webpackChunkName: "NewsletterPage" */ '@app/components/Pages/Newsletter/Newsletter')), params);
const FindVet = loadable(() => loadableDelay(import(/* webpackChunkName: "FindVet" */ '@app/components/Pages/FindVet/FindVet')), params);
const AreasList = loadable(() => loadableDelay(import(/* webpackChunkName: "AreasList" */ '@app/components/Pages/AreasList/AreasList')), params);
const Area = loadable(() => loadableDelay(import(/* webpackChunkName: "Area" */ '@app/components/Pages/Area/Area')), params);
const FaqPage = loadable(() => loadableDelay(import(/* webpackChunkName: "FaqBlock" */ '@app/components/Pages/Resources/FaqPage/FaqPage')), params);
const PetMemorial = loadable(() => loadableDelay(import(/* webpackChunkName: "PetMemorial" */ '@app/components/Pages/PetMemorial/PetMemorial')), params);
const CareCoordinator = loadable(
	() => loadableDelay(import(/* webpackChunkName: "CareCoordinator" */ '@app/components/Pages/AboutUs/Careers/CareCoordinator')),
	params,
);
const Veterinarian = loadable(
	() => loadableDelay(import(/* webpackChunkName: "CareCoordinator" */ '@app/components/Pages/AboutUs/Careers/Veterinarian')),
	params,
);
const VetTestimonials = loadable(
	() => loadableDelay(import(/* webpackChunkName: "VetTestimonials" */ '@app/components/Pages/VetTestimonials/VetTestimonials')),
	params,
);
const AboutUs = loadable(() => loadableDelay(import(/* webpackChunkName: "AboutUs" */ '@app/components/Pages/AboutUs/AboutUs')), params);
const StudentExternship = loadable(
	() => loadableDelay(import(/* webpackChunkName: "AboutUs" */ '@app/components/Pages/AboutUs/StudentExternship')),
	params,
);
const NotFound = loadable(() => loadableDelay(import(/* webpackChunkName: "AboutUs" */ '@app/components/Pages/NotFound/NotFound')), params);
const PetLossSupport = loadable(
	() => loadableDelay(import(/* webpackChunkName: "PetLossSupport" */ '@app/components/Pages/PetLossSupport/PetLossSupport')),
	params,
);
const PetLossBeginningToHeal = loadable(
	() => loadableDelay(import(/* webpackChunkName: "PetLossBeginningToHeal" */ '@app/components/Pages/PetLossBeginningToHeal/PetLossBeginningToHeal')),
	params,
);
const PetLossChildrenGrief = loadable(
	() => loadableDelay(import(/* webpackChunkName: "PetLossChildrenGrief" */ '@app/components/Pages/PetLossChildrenGrief/PetLossChildrenGrief')),
	params,
);
const SupportCenterTeam = loadable(
	() => loadableDelay(import(/* webpackChunkName: "SupportCenterTeam" */ '@app/components/Pages/AboutUs/SupportCenterTeam/SupportCenterTeam')),
	params,
);
const HowWillIKnowItsTime = loadable(() => loadableDelay(import('@app/components/Pages/HowWillIKnowItsTime/HowWillIKnowItsTime')), params);
const Blog = loadable(() => loadableDelay(import(/* webpackChunkName: "Blog" */ '@app/components/Pages/Blog/Blog')), params);
const Article = loadable(() => loadableDelay(import(/* webpackChunkName: "Article" */ '@app/components/Pages/Article/Article')), params);
const News = loadable(() => loadableDelay(import(/* webpackChunkName: "News" */ '@app/components/Pages/News/News')), params);
const Videos = loadable(() => loadableDelay(import(/* webpackChunkName: "Videos" */ '@app/components/Pages/Videos/Videos')), params);
const QualityOfLifeAssessment = loadable(
	() => loadableDelay(import(/* webpackChunkName: "QualityOfLifeAssessment" */ '@app/components/Pages/QualityOfLife/QualityOfLifeAssessment')),
	params,
);
const QOLQuestionary = loadable(
	() => loadableDelay(import(/* webpackChunkName: "QualityOfLifeAssessment" */ '@app/components/Pages/QualityOfLife/QOLQuestionary')),
	params,
);
const QOLResults = loadable(
	() => loadableDelay(import(/* webpackChunkName: "QualityOfLifeAssessment" */ '@app/components/Pages/QualityOfLife/QOLResults')),
	params,
);
const VetResourceCenter = loadable(
	() => loadableDelay(import(/* webpackChunkName: "VetResourceCenter" */ '@app/components/Pages/Resources/VetResources/VetResourceCenter')),
	params,
);
const VetResourceFAQ = loadable(
	() => loadableDelay(import(/* webpackChunkName: "VetResourceFAQ" */ '@app/components/Pages/Resources/VetResources/VetResourcesFAQ')),
	params,
);
const VetResourcesLogged = loadable(
	() => loadableDelay(import(/* webpackChunkName: "VetResourcesLogged" */ '@app/components/Pages/Resources/VetResources/VetResourcesLogged')),
	params,
);
const FamilyResources = loadable(
	() => loadableDelay(import(/* webpackChunkName: "FamilyResources" */ '@app/components/Pages/Resources/FamilyResources/FamilyResources')),
	params,
);
const Covid19 = loadable(() => loadableDelay(import(/* webpackChunkName: "Covid19" */ '@app/components/Pages/Covid19/Covid19')), params);
const QOLDifferentSpecies = loadable(
	() => loadableDelay(import(/* webpackChunkName: "QOLDifferentSpecies" */ '@app/components/Pages/QualityOfLife/QOLDifferentSpecies')),
	params,
);
const Policies = loadable(() => loadableDelay(import(/* webpackChunkName: "Policies" */ '@app/components/Pages/Policies/Policies')), params);
const OnlineBooking = loadable(
	() => loadableDelay(import(/* webpackChunkName: "OnlineBooking" */ '@app/components/Pages/Booking/Booking')),
	params,
);

export const routes = (
	<LayoutWrapper>
		<Switch>
			<Route exact path={['/veterinarian-testimonials/:id?']}>
				<Layout withScrollTop={false}>
					<Route exact path="/veterinarian-testimonials/:id?" component={VetTestimonials} />
				</Layout>
			</Route>
			<Route
				exact
				path={[
					'/',
					'/our-services/veterinary-hospice',
					'/our-services/in-home-euthanasia',
					'/our-services/aftercare',
					'/our-services/aftercare/burial',
					'/our-services/aftercare/aquamation',
					'/our-services/telehospice',
					'/our-services/pet-loss-support',
					'/angel-fund',
					'/newsletter',
					'/careers/become-a-veterinarian',
					'/careers/become-a-care-coordinator',
					'/about-us/who-we-are',
					'/about-us/student-externship',
					'/pet-loss-support-resources',
					'/pet-loss-support-resources/healing-process',
					'/pet-loss-support-resources/children-and-pet-grief-resources',
					'/how-will-i-know-it-is-time',
				]}
			>
				<Layout>
					<Route exact path="/" component={Home} />
					<Route exact path="/our-services/veterinary-hospice" component={VeterinaryHospice} />
					<Route exact path="/our-services/in-home-euthanasia" component={Euthanasia} />
					<Route exact path="/our-services/aftercare" component={Aftercare} />
					<Route exact path="/our-services/aftercare/burial" component={Burial} />
					<Route exact path="/our-services/aftercare/aquamation" component={Aquamation} />
					<Route exact path="/our-services/telehospice" component={Teleadvice} />
					<Route exact path="/our-services/pet-loss-support" component={PetLossSupportGroup} />
					<Route exact path="/angel-fund" component={AngelFund} />
					<Route exact path="/careers/become-a-veterinarian" component={Veterinarian} />
					<Route exact path="/careers/become-a-care-coordinator" component={CareCoordinator} />
					<Route exact path="/about-us/who-we-are" component={AboutUs} />
					<Route exact path="/about-us/student-externship" component={StudentExternship} />
					<Route exact path="/newsletter" component={Newsletter} />
					<Route exact path="/pet-loss-support-resources" component={PetLossSupport} />
					<Route exact path="/pet-loss-support-resources/healing-process" component={PetLossBeginningToHeal} />
					<Route exact path="/pet-loss-support-resources/children-and-pet-grief-resources" component={PetLossChildrenGrief} />
					<Route exact path="/how-will-i-know-it-is-time" component={HowWillIKnowItsTime} />
				</Layout>
			</Route>
			<Route
				exact
				path={[
					'/find-a-vet',
					'/find-a-vet/search',
					'/quality-of-life-assessment',
					'/quality-of-life-assessment/question/:id',
					'/quality-of-life-assessment/results/:id?',
					'/about-us/support-center-team',
					'/veterinary-resource-center',
					'/covid19',
					'/policies/:item?',
					'/online-booking/:step?',
				]}
			>
				<LayoutCut>
					<Route exact path="/find-a-vet" component={FindVet} />
					<Route exact path="/find-a-vet/search" component={AreasList} />
					<Route exact path="/about-us/support-center-team" component={SupportCenterTeam} />
					<Route exact path="/quality-of-life-assessment" component={QualityOfLifeAssessment} />
					<Route exact path="/quality-of-life-assessment/question/:id" component={QOLQuestionary} />
					<Route exact path="/quality-of-life-assessment/results/:id?" component={QOLResults} />
					<Route exact path="/veterinary-resource-center" component={VetResourceCenter} />
					<Route exact path="/covid19" component={Covid19} />
					<Route exact path="/policies/:item?" component={Policies} />
					<Route exact path="/online-booking/:step?" component={OnlineBooking} />
				</LayoutCut>
			</Route>

			<Route exact path={['/pet-memorial/:id?', '/find-a-vet/:area/:id/:tab', '/in-the-news/:id?', '/veterinary-resources/:topic/logged']}>
				<LayoutCut withScrollTop={false}>
					<Route exact path="/pet-memorial/:id?" component={PetMemorial} />
					<Route exact path="/find-a-vet/:area/:id/:tab" component={Area} />
					<Route exact path="/in-the-news/:id?" component={News} />
					<Route exact path="/veterinary-resources/:topic/logged" component={VetResourcesLogged} />
				</LayoutCut>
			</Route>

			<Route exact path={['/faq', '/blog', '/videos', '/resource-center/:topic/:resource', '/resource-center/:topic', '/resource-center']}>
				<LayoutFamilyResources>
					<Route component={FamilyResources} />
				</LayoutFamilyResources>
			</Route>
			<Route exact path={['/faq/:id?', '/blog/:id?']}>
				<LayoutFamilyResources withScrollTop={false}>
					<Route component={FamilyResources} />
				</LayoutFamilyResources>
			</Route>

			<Route path={['/blog/:topic/:id']}>
				<LayoutFamilyResources>
					<Route exact path="/blog/:topic/:id" component={Article} />
				</LayoutFamilyResources>
			</Route>

			<Route
				path={[
					'/quality-of-life-assessment/different-species',
				]}
			>
				<LayoutShort>
					<Route exact path="/quality-of-life-assessment/different-species" component={QOLDifferentSpecies} />
				</LayoutShort>
			</Route>
			<Route>
				<LayoutCut>
					<Route component={NotFound} />
				</LayoutCut>
			</Route>
		</Switch>
	</LayoutWrapper>
);
