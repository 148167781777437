import React from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';

import AttentionMessagesWrapper from '@app/components/UI/AttentionMessage/AttentionMessagesWrapper';
import CookieBanner from '@app/components/UI/CookieBanner/CookieBanner';
import { ApplicationState } from '@app/store';

const LayoutWrapper = (props) => {
	const history = useHistory();

	const hiddenStyles: React.CSSProperties = {
		display: 'none',
		visibility: 'hidden',
	};

	React.useEffect(() => {
		if (!props.debug) {
			ReactGA.initialize('UA-26388509-1');
		}
	}, []);

	React.useEffect(() => {
		ReactGA.pageview(history.location.pathname + history.location.search);
	}, [history.location.pathname, history.location.search]);

	return (
		<>
			<noscript>
				<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-53KG2QP" height="0" width="0" style={hiddenStyles} title="ns.html?id=GTM-53KG2QP" />
			</noscript>
			{props.children}
			<CookieBanner />
			<AttentionMessagesWrapper />
		</>
	);
};
export default withRouter(connect<any, any, any, ApplicationState>((state: ApplicationState) => state.login)(LayoutWrapper));
