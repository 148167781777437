const listenerForBrowser = (mql, listenerFunc) => {
	try {
		mql.addEventListener('change', listenerFunc);
	} catch (error) {
		try {
			mql.addListener(listenerFunc);
		} catch (e) {
			console.error(e);
		}
	}
};

export default listenerForBrowser;
