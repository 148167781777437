import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import ScrollToTop from '@app/components/LocalCommon/ScrollToTop/ScrollToTop';
import Footer from '@app/components/UI/Footer/Footer';
import Header from '@app/components/UI/Header/Header';
import '@app/scss/style.scss';

type PageProps = RouteComponentProps & { withScrollTop: boolean };

const LayoutCut: React.FC<PageProps> = ({ children, withScrollTop = true, ...rest }) => {
	return (
		<div className="app-container">
			<Header />
			<div className="app-container-content">{children}</div>
			<Footer />
			{withScrollTop && <ScrollToTop />}
		</div>
	);
};

export default withRouter(LayoutCut) as any;
