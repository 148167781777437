import { fetch } from 'domain-task';

import { ResponseError, request as baseRequest } from '@common/react/components/Api';
import { BaseParams } from '@common/typescript/objects/BaseParams';

import { User } from '@app/objects/User';
import { ApplicationState } from '@app/store';

interface Message<T> {
	success: number;
	data: T;
	session: string;
}

export function request<T>(type: any, data?: any, state?: ApplicationState) {
	return baseRequest<T, User, ApplicationState>(type, data, state);
}

export const rest = {
	post: <T>(url: string, data: BaseParams, state?: ApplicationState) => baseApiRequest<T>('POST', url, data, state),
	get: <T>(url: string, state?: ApplicationState) => baseApiRequest<T>('GET', url, null, state),
};

function baseApiRequest<T>(method: string, url: string, data: BaseParams | null = null, state?: ApplicationState): Promise<T> {
	return fetch(`api/${url}`, {
		credentials: 'same-origin',
		method,
		headers: {
			'Content-type': 'application/json; charset=utf-8',
			Cookie: `session=${state ? state.login.session : ''}`,
		},
		...(data && {
			body: JSON.stringify(data),
		}),
	})
		.then((response) => response.json() as Message<T | ResponseError>)
		.then((data: Message<T | ResponseError>) => {
			if (!data.success) {
				// throw data.data as ResponseError;
				console.log('Error');
				return data as any;
			}

			return data.data ? (data.data as T) : (data as any);
		});
}
